<template>
  <b-modal
    :visible="value"
    centered
    title="Groups"
    size="lg"
    scrollable
    hide-footer
    body-class="position-static p-0 pb-2"
    @hide="handeModalHide"
  >
    <b-table
      hover
      class="m-0"
      :items="groups"
      :fields="fields"
    >
      <template #head(actions)>
        <b-button
          size="sm"
          variant="primary"
          class="float-right"
          @click="onCreateButtonClick"
        >
          Create
        </b-button>
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex align-items-center justify-content-end font-size-20">
          <i
            class="bx bx-pencil text-warning mr-2 cursor-pointer"
            @click="onEditIconClick(item)"
          />
          <i
            class="bx bx-trash text-danger cursor-pointer"
            @click="handleDelete(item)"
          />
        </div>
      </template>
    </b-table>
    <b-modal
      v-model="isModalOpen"
      :title="modalTitle"
      centered
      body-class="position-static"
    >
      <input
        type="text"
        v-model="title"
        class="form-control w-100"
        placeholder="Group name"
      />
      <template #modal-footer>
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            class="mr-3"
            @click="closeModal"
          >
            Cancel
          </b-button>
          <b-button
            variant="success"
            :disabled="isSubmitButtonDisabled"
            @click="handleSubmit"
          >
            {{ isCreateModeEnabled ? 'Create' : 'Save' }}
          </b-button>
        </div>
      </template>
      <loader :show="modalLoading" size="small" />
    </b-modal>
    <loader :show="mainLoading" size="small" />
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import Loader from '../Loader.vue';

export default {
  components: { Loader },
  props: {
    value: Boolean
  },
  data() {
    return {
      title: '',
      mainLoading: false,
      modalLoading: false,
      isModalOpen: false,
      editableGroupId: null,
      fields: [
        { key: 'id', thStyle: { width: '50px' }, },
        'title',
        { key: 'actions', label: '' }
      ]
    }
  },
  async mounted() {
    this.mainLoading = true;
    await this.getGroups();
    this.mainLoading = false;
  },
  methods: {
    ...mapActions(
      'appsWidget', ['getGroups', 'createGroup', 'deleteGroup', 'editGroup']
    ),
    closeModal() {
      this.isModalOpen = false;
    },
    onCreateButtonClick() {
      this.title = '';
      this.isModalOpen = true;
      this.editableGroupId = null;
    },  
    onEditIconClick({ id, title }) {
      this.title = title;
      this.isModalOpen = true;
      this.editableGroupId = id;
    },
    async handleSubmit() {
      this.modalLoading = true;
      if (this.isCreateModeEnabled) {
        await this.createGroup({ title: this.title });
      } else {
        await this.editGroup({
          id: this.editableGroupId,
          data: { title: this.title }
        });
      }
      await this.getGroups();
      this.isModalOpen = false;
      this.modalLoading = false;
    },
    async handleDelete(group) {
      this.$bvModal.msgBoxConfirm(`Delete ${group.title} group?`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              this.mainLoading = true;
              await this.deleteGroup(group.id);
              await this.getGroups();
              this.mainLoading = false;
            }
          });
    },
    handeModalHide() {
      this.$emit('input', false);
    }
  },
  computed: {
    modalTitle() {
      return this.isCreateModeEnabled ? 'Create Group' : 'Edit Group';
    },
    isCreateModeEnabled() {
      return this.editableGroupId === null;
    },
    isSubmitButtonDisabled() {
      return !this.title || this.groups.some(({ title }) => title === this.title);
    },
    groups() {
      return this.$store.state.appsWidget.groups;
    }
  }
}
</script>
