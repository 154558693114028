<template>
  <b-modal
    :visible="value"
    centered
    title="Create App"
    size="md"
    body-class="position-static"
    @hide="handeModalHide"
  >
    <b-form-group
      label="Title"
      label-for="title"
      class="required"
    >
      <b-form-input
        id="title"
        v-model="form.title"
        :class="{ 'is-invalid': $v.form.title.$error }"
      />
    </b-form-group>
    <b-form-group
      v-if="!initialData"
      label="Icon"
      label-for="icon"
      class="required"
    >
      <b-form-file
        id="icon"
        v-model="form.icon"
        accept="image/*"
        placeholder="Choose a icon or drop it here..."
        drop-placeholder="Drop icon here..."
        :state="$v.form.icon.$error ? false : null"
      />
    </b-form-group>
    <b-form-group
      label="Account Service"
      :class="{ required: !form.keyclockService }"
    >
      <multiselect
        v-model="form.accountService"
        :options="accountServices"
        track-by="id"
        :searchable="true"
        :show-labels="false"
        label="title"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.accountService.$error }"
        placeholder="Choose Service"
      />
    </b-form-group>
    <b-form-group
      label="Keyclock Service"
      :class="{ required: !form.accountService }"
    >
      <multiselect
        v-model="form.keyclockService"
        :options="keyclockServices"
        track-by="client_id"
        :searchable="true"
        :show-labels="false"
        label="title"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.keyclockService.$error }"
        placeholder="Choose Service"
      />
    </b-form-group>
    <b-form-group
      label="Url"
      label-for="url"
      class="required"
    >
      <b-form-input
        id="url"
        v-model="form.url"
        :class="{ 'is-invalid': $v.form.url.$error }"
      />
    </b-form-group>
    <b-form-group
      class="required"
      label="Group"
    >
      <multiselect
        v-model="form.group"
        :options="groups"
        track-by="id"
        :searchable="true"
        :show-labels="false"
        label="title"
        class="app-multiselect"
        :class="{ 'is-invalid': $v.form.group.$error }"
        placeholder="Choose Group"
      />
    </b-form-group>
    <b-form-checkbox
      id="show-to-all"
      v-model="form.showToAll"
      :unchecked-value="false"
    >
      Show to all
    </b-form-checkbox>
    <b-form-checkbox
      id="active"
      class="mt-2"
      v-model="form.isActive"
      :unchecked-value="false"
    >
      Active
    </b-form-checkbox>
    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-end">
        <b-button
          class="mr-3"
          @click="handeModalHide"
        >
          Cancel
        </b-button>
        <b-button
          variant="success"
          :disabled="loading"
          @click="handleSubmit"
        >
          {{ isCreateModeEnabled ? 'Create' : 'Save' }}
        </b-button>
      </div>
    </template>
    <loader :show="loading" size="small" />
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, requiredIf } from 'vuelidate/lib/validators';
import Loader from '../Loader.vue';
import { convertObjectToFormData } from '@/utils/converters';

export default {
  components: { Loader },
  props: {
    value: Boolean,
    initialData: Object
  },
  data() {
    return {
      loading: false,
      form: {
        url: '',
        title: '',
        icon: null,
        group: null,
        accountService: null,
        keyclockService: null,
        showToAll: true,
        isActive: true
      }
    }
  },
  validations() {
    return {
      form: {
        url: { required },
        icon: {
          required: requiredIf(function() {
            return !this.initialData;
          })
        },
        title: { required },
        group: { required },
        accountService: {
          required: requiredIf(function() {
            return !this.form.keyclockService;
          })
        },
        keyclockService: {
          required: requiredIf(function() {
            return !this.form.accountService;
          })
        },
      }
    }
  },
  methods: {
    ...mapActions('appsWidget', [
      'createApp',
      'updateApp',
      'uploadAppIcon'
    ]),
    async handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        if (this.isCreateModeEnabled) {
          const app = await this.createApp(this.createPayload());
          if (app?.id) {
            await this.uploadAppIcon(convertObjectToFormData({
              files: [this.form.icon],
              type: 'service',
              attachable_id: app.id
            }));
            this.$emit('on-submit');
          }
        } else {
          await this.updateApp({
            id: this.initialData.id,
            data: this.createPayload()
          });
          this.$emit('on-submit');
        }
        this.loading = false;
      }
    },
    createPayload() {
      return {
        account_service_id: this.form.accountService?.id ?? null,
        keyclock_client_id: this.form.keyclockService?.client_id ?? null,
        title: this.form.title,
        url: this.form.url,
        group_id: this.form.group.id,
        show_to_all: this.form.showToAll,
        active: this.form.isActive
      }
    },
    parseInitialData() {
      this.form.url = this.initialData?.url ?? '';
      this.form.title = this.initialData?.title ?? '';
      this.form.group = this.groups.find(({ id }) => id === this.initialData?.group_id) ?? null;
      this.form.accountService = this.accountServices.find(({ id }) => id === this.initialData?.account_service_id) ?? null;
      this.form.keyclockService = this.keyclockServices.find(({ client_id }) => client_id === this.initialData?.keyclock_client_id) ?? null;
      this.form.showToAll = this.initialData?.show_to_all ?? true;
      this.form.isActive = this.initialData?.active ?? true;
    },
    handeModalHide() {
      this.$emit('input', false);
    }
  },
  computed: {
    ...mapState('appsWidget', [
      'groups',
      'accountServices',
      'keyclockServices'
    ]),
    isCreateModeEnabled() {
      return !this.initialData;
    }
  },
  watch: {
    value() {
      if (this.value) {
        this.$v.$reset();
        this.parseInitialData();
      }
    }
  }
}

</script>
