<template>
  <layout :loading="loading">
    <div class="d-flex align-items-center justify-content-between flex-column gap-1 flex-sm-row mb-4">
      <h2 class="title-1 pb-0">Widget Settings</h2>
      <div class="d-flex align-items-center mt-2 mt-sm-0 ">
        <b-button
          variant="warning"
          @click="openGroupModal"
        >
          Groups
        </b-button>
        <b-button
          class="ml-2"
          variant="primary"
          @click="onCreateAppClick"
        >
          Create App
        </b-button>
      </div>
    </div>
    <b-table
      hover
      class="m-0"
      :items="apps"
      :fields="fields"
      responsive
    >
      <template #cell(photoUrl)="{ value, index, item }">
        <div class="app-icon">
          <img :src="value" alt="" />
          <div class="app-icon__uploader">
            <label :for="`app-icon-input-${index}`">
              <input
                :id="`app-icon-input-${index}`"
                type="file"
                accept="image/*"
                @change="handleIconChange($event, item.id)"
              />
            </label>
            <i class="bx bx-cloud-upload text-primary" />
          </div>
        </div>
      </template>
      <template #cell(active)="{ item, value }">
        <b-form-checkbox
          switch
          :checked="value"
          @change="handleSwitchChange($event, item.id, 'active')"
        />
      </template>
      <template #cell(show_to_all)="{ item, value }">
        <b-form-checkbox
          switch
          :checked="value"
          @change="handleSwitchChange($event, item.id, 'show_to_all')"
        />
      </template>
      <template #cell(actions)="{ item }">
        <div class="d-flex align-items-center justify-content-end font-size-20">
          <i
            class="bx bx-pencil text-warning mr-2 cursor-pointer"
            @click="onEditIconClick(item)"
          />
          <i
            class="bx bx-trash text-danger cursor-pointer"
            @click="handleDelete(item)"
          />
        </div>
      </template>
    </b-table>
    <apps-widget-form
      v-model="isAppModalOpen"
      :initial-data="editableApp"
      @on-submit="onFormSubmit"
    />
    <apps-widget-groups v-model="isGroupsModalOpen" />
  </layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { convertObjectToFormData } from '@/utils/converters';
import AppsWidgetForm from '@/components/apps-widget/apps-widget-form.vue';
import AppsWidgetGroups from '@/components/apps-widget/apps-widget-groups.vue';

export default {
  components: { AppsWidgetGroups, AppsWidgetForm },
  data() {
    return {
      loading: false,
      editableApp: null,
      isAppModalOpen: false,
      isGroupsModalOpen: false,
      fields: [
        { key: 'photoUrl', label: '', thStyle: { width: '74px' } },
        { key: 'title', label: 'Name' },
        { key: 'group.title', label: 'Group' },
        { key: 'url', label: 'Url' },
        { key: 'active', label: 'Active', thStyle: { width: '65px' } },
        { key: 'show_to_all', label: 'Show To All', thStyle: { width: '100px', minWidth: '100px' } },
        { key: 'actions', label: '', thStyle: { width: '72px' } }
      ],
    }
  },
  async mounted() {
    this.loading = true;
    await this.getApps();
    await this.getServices();
    this.loading = false;
  },
  methods: {
    ...mapActions(
      'appsWidget', [
        'getApps',
        'deleteApp',
        'updateApp',
        'getServices',
        'uploadAppIcon'
      ]
    ),
    openGroupModal() {
      this.isGroupsModalOpen = true;
    },
    onCreateAppClick() {
      this.editableApp = null;
      this.isAppModalOpen = true;
    },
    async onFormSubmit() {
      this.isAppModalOpen = false;
      this.loading = true;
      await this.getApps();
      this.loading = false;
    },
    onEditIconClick(app) {
      this.editableApp = app;
      this.isAppModalOpen = true;
    },
    handleDelete(app) {
      this.$bvModal.msgBoxConfirm(`Delete ${app.title} app?`, {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              this.loading = true;
              await this.deleteApp(app.id);
              await this.getApps();
              this.loading = false;
            }
          });
    },
    async handleSwitchChange(value, id, field) {
      this.loading = true;
      await this.updateApp({ id, data: { [field]: value } });
      await this.getApps();
      this.loading = false;
    },
    async handleIconChange(event, id) {
      this.loading = true;
      await this.uploadAppIcon(convertObjectToFormData({
        files: [...event.target.files],
        type: 'service',
        attachable_id: id,
        replace: true
      }));
      await this.getApps();
      this.loading = false;
    }
  },
  computed: {
    ...mapState('appsWidget', ['apps', 'accountServices', 'keyclockServices'])
  }
}
</script>

<style lang="scss" scoped>
.app-icon {
  width: 50px;
  height: 50px;
  overflow: hidden;
  position: relative;
  border-radius: 4px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__uploader {
    top: 100%;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    transition: all .2s linear;
    background-color: rgba(255, 255, 255, .7);

    label {
      top: 0px;
      left: 0px;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;

      input {
        display: none;
      }
    }

    i {
      font-size: 25px;
    }
  }

  &:hover &__uploader {
    top: 0px;
  }
}
:deep(.table) td {
  vertical-align: middle;
}
</style>
